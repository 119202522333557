.about__wrapper {
  padding: 80px;
  padding-top: 20px;
  display: flex;
  gap: 200px;
  overflow: hidden;
  position: relative;
  z-index: 999;
  height: 100%;
  width: 100%;
}

.about__text {
  position: relative;
  z-index: 999;
  width: 500px;
}

.about__text h1 {
  color: var(--color-pink);
  font-family: var(--ff-primary);
  font-size: var(--fs-title-primary);
  padding-bottom: 30px;
}

.about__title--wrapper {
  overflow: hidden;
}

.about__text h1::after {
  content: "";
  width: 50%;
  height: 0.5px;
  background-color: var(--color-white-20);
  position: absolute;
  top: 60px;
  left: 0;
}

.about__text p {
  color: var(--color-white);
  font-family: var(--ff-secondary);
  font-size: var(--fs-text);
  line-height: var(--text-line-height);
  padding-bottom: 15px;
}

.about__gradientOrb--wrapper {
  position: absolute;
  right: -500px;
  top: 0;
  z-index: 0;
  /* overflow: hidden;
  background-color: red; */
}

.about__portrait--wrapper {
  position: relative;
  z-index: 999;
  height: 600px;
  object-fit: contain;
}

.about__portrait--wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .about__wrapper {
    padding: 60px;
    display: flex;
    gap: 80px;
  }

  .about__text h1 {
    text-align: center;
  }

  .about__text h1::after {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

quote {
  color: var(--color-pink);
}

@media (max-width: 750px) {
  .about__wrapper {
    display: block;
    padding: 60px;
  }
  .about__text {
    padding-bottom: 60px;
    text-align: center;
    width: auto;
  }
  .about__text h1::after {
    top: 50px;
  }

  .about__gradientOrb--wrapper {
    display: none;
  }
}

@media (max-width: 414px) {
  .about__text h1::after {
    top: 40px;
  }
  .about__text h1 {
    padding-bottom: 20px;
  }
  .about__wrapper {
    padding: 30px;
  }
}
