img,
svg {
  display: block;
  max-width: 100%;
}

.projectPage__wrapper {
  display: flex;
  padding: 60px;
}

.projectPage__about h1 {
  color: var(--color-pink);
  font-family: var(--ff-primary);
  font-size: var(--fs-title-primary);
  padding-bottom: 30px;
  text-align: center;
}

.projectPage__about p {
  color: var(--color-white);
  font-family: var(--ff-secondary);
  font-size: var(--fs-text);
  line-height: var(--text-line-height);
  padding-bottom: 15px;
  text-align: center;
  padding-left: 280px;
  padding-right: 280px;
}

.projectPage__skillPills {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.skillPills {
  background-color: var(--color-white-20);
  padding: 10px 15px 10px 15px;
  border-radius: 50px;
  text-align: center;
}

.skillPills p {
  font-size: 10px;
}

.mockup {
  padding-top: 30px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  margin-inline: auto;
}

.mockup__desktop {
  grid-row: span 2;
  grid-column: span 2;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mockup__desktop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mockup__mobile {
  grid-row: span 2;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mockup__mobile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mockup__links {
  display: flex;
  grid-column: span 3;
  grid-column: 1fr 1fr 1fr;
  gap: 20px;
}

.mockup__link {
  margin-top: 10px;
  display: block;
  flex: 1 0 0;
  align-self: stretch;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-back-30);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  backdrop-filter: blur(7px);
  font-size: var(--fs-text);
  transition: 400ms;
}

.mockup__link:hover {
  background-color: var(--color-white-20);
}

/* a {
  all: unset;
} */

p {
  color: var(--color-white);
}

.button-border__corner {
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
}

.button-border__corner.--1 {
  top: -1px;
  left: -1px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
}

.button-border__corner.--2 {
  top: -1px;
  right: -1px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.button-border__corner.--3 {
  bottom: -1px;
  right: -1px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.button-border__corner.--4 {
  bottom: -1px;
  left: -1px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
}

.projectPage__about,
.projectPage__skillPills,
.mockup {
  position: relative;
  z-index: 999;
}

.projectPage__gradientOrb {
  position: absolute;
  z-index: -999;
  left: 50%;
  transform: translateX(-50%);
  bottom: -650px;
}
@media (max-width: 1179px) {
  .projectPage__about p {
    padding-left: 200px;
    padding-right: 200px;
  }
}

@media (max-width: 1024px) {
  .projectPage__wrapper {
    display: flex;
    padding: 20px;
  }

  .projectPage__about p {
    padding-bottom: 15px;
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
  }

  .mockup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .mockup__desktop {
    grid-column: span 2;
  }

  .mockup__mobile {
    grid-column: span 1;
  }

  .mockup__links {
    grid-column: span 1;
    display: grid;
  }

  .mockup__link {
    height: 150px;
  }
}

@media (max-width: 750px) {
  .mockup__link {
    height: 100px;
  }

  .mockup {
    display: block;
  }

  .mockup__desktop {
    aspect-ratio: 1 / 1;
  }

  .mockup__mobile {
    aspect-ratio: 1 / 1;
    padding-top: 10px;
  }

  .mockup__links {
    padding-top: 10px;
    gap: 10px;
  }
}

@media (max-width: 585px) {
  .projectPage__about p {
    padding-left: 10px;
    padding-right: 10px;
  }

  .projectPage__about h1 {
    padding-bottom: 10px;
  }

  .skillPills p {
    font-size: 8px;
  }
}
/* 
@media (max-width: 414px) {
  .projectPage__about p {
    padding-left: 10px;
    padding-right: 10px;
  }
} */
