.button-border {
  padding: 20px 26px;
  background-color: var(--color-back-30);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  max-width: max-content;
  backdrop-filter: blur(7px);
  font-size: var(--fs-text);
  transition: 400ms;
}

.button-border:hover {
  background-color: var(--color-white-20);
}

p {
  color: var(--color-white);
}

/* .button-border :hover::before {
  content: "ABOUT ME";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  animation: animated-gradient 1s;
}

@keyframes animated-gradient {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
} */

.button-border__corner {
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
}

.button-border__corner.--1 {
  top: -1px;
  left: -1px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
}

.button-border__corner.--2 {
  top: -1px;
  right: -1px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.button-border__corner.--3 {
  bottom: -1px;
  right: -1px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.button-border__corner.--4 {
  bottom: -1px;
  left: -1px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
}

.button__border--corner {
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
}

.button__border--corner1 {
  top: -1px;
  left: -1px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
}
