/* .header {
  color: var(--color-white);
  text-align: center;
}

.header h1 {
  font-size: var(--fs-hero);
  font-family: var(--ff-primary);
}

.blur {
  color: transparent;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);


} */
/* 
@keyframes float {
  from,
  to {
    transform: translateY(-0%);
  }

  50% {
    transform: translateY(-3%);
  }
} */
.star {
  position: relative;
  top: -80px;
  left: 60px;
}

.star p {
  position: relative;
  top: -120px;
  left: 0px;
  font-size: 12px;
  /* opacity: 0; */
  /* transition: 300ms; */
  animation: spin 12s linear infinite;
}

/* .centered-content:hover p {
  opacity: 1;
} */

.centered-content {
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.centered-content img {
  height: 220px;
  animation: spin 12s linear infinite;
}

/* .star p {
  background-color: red;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* .star img {
  height: 220px;
  animation: spin 12s linear infinite;
} */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  color: var(--color-white);
  text-align: center;
  height: 400px;
}

.header h1 {
  font-family: var(--ff-primary);
  /* animation: float 5s ease infinite; */
}

.header__creative {
  font-size: 180px;
  position: relative;
  z-index: 9;
}

.header__developer {
  font-size: 280px;
  -webkit-text-fill-color: var(
    --color-black
  ); /* Will override color (regardless of order) */
  -webkit-text-stroke: 0.75px white;
  position: relative;
  top: -150px;
  z-index: 0;
}

@media (max-width: 1323px) {
  .header {
    height: 360px;
  }

  .header__creative {
    font-size: 160px;
  }
  .header__developer {
    font-size: 240px;
  }
}

@media (max-width: 1143px) {
  .header {
    height: 340px;
  }

  .header__creative {
    font-size: 140px;
  }
  .header__developer {
    font-size: 200px;
  }
}

@media (max-width: 955px) {
  .header {
    height: 300px;
  }

  .header__creative {
    font-size: 100px;
  }
  .header__developer {
    top: -80px;
    font-size: 180px;
  }
}

@media (max-width: 864px) {
  .header {
    height: 280px;
  }
  .header__creative {
    font-size: 100px;
  }
  .header__developer {
    font-size: 150px;
  }
}

@media (max-width: 720px) {
  .header {
    height: 220px;
  }
  .header__creative {
    font-size: 100px;
  }
  .header__developer {
    font-size: 120px;
  }

  .star {
    width: 175px;
  }
}

@media (max-width: 576px) {
  .header {
    height: 200px;
  }
  .header__creative {
    font-size: 80px;
  }
  .header__developer {
    top: -50px;
    font-size: 100px;
  }

  .star {
    width: 150px;
  }

  .start p {
    font-size: 8px;
  }
}

@media (max-width: 483px) {
  .header {
    height: 140px;
  }
  .header__creative {
    font-size: 60px;
  }
  .header__developer {
    top: -50px;
    font-size: 80px;
  }

  .star {
    width: 100px;
  }

  .star p {
    display: none;
  }
}

/*
//414 - 749
@media (max-width: 750px)

//750 - 1023
@media (max-width: 1024px) {

  //1024 - 1439
@media (max-width: 1440px) {

  //1440 - 1920
@media (max-width: 1920px) {

      //2560++
@media (min-width: 2560px) {

*/
