.projectContainer {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  padding-top: 20px;
  background-color: var(--color-black);
  position: relative;
  z-index: 999;
  /* animation: float 5s ease infinite; */
}
/* @keyframes float {
  from,
  to {
    transform: translateY(-0%);
  }

  50% {
    transform: translateY(-3%);
  }
} */

/* .projectContainer a {
  text-decoration: none;
  background-color: red;
  min-height: 100%;
} */

@media (max-width: 1280px) {
  .projectContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 890px) {
  .projectContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 10px;
  }
}

@media (max-width: 414px) {
  .projectContainer {
    display: flex;
    overflow-x: scroll;
    gap: 15px;
  }
}
